
import { apiGameAppPackSetMealDel, apiGameAppPackSetMealLists, apiGameAppPackSetMealStatus } from '@/api/shop'
import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
    components: {
        LsDialog,
        LsPagination,
        ExportData
    }
})
export default class AppPackSetMealLists extends mixins() {
    queryObj = {
        title: ''
    }
    pager = new RequestPaging()
    apiGameAppPackSetMealLists = apiGameAppPackSetMealLists

    initAppList = [{id: '', name:"全部"}]

    getList(page?: number) {
        page && (this.pager.page = page)
        return this.pager.request({
            callback: apiGameAppPackSetMealLists,
            params: {
                ...this.queryObj,
                aid: 0,
            }
        })
    }
    handleReset() {
        this.queryObj = {
            title: ''
        }
        this.getList()
    }
    handleStatus(value: number, id: number) {
      apiGameAppPackSetMealStatus({
            id,
            is_show: value,
            aid: 0
        }).then(() => {
            this.getList()
        })
    }

    handleDelete(id: number) {
      apiGameAppPackSetMealDel({
            id,
            aid: 0
        }).then(() => {
            this.getList()
        })
    }

    async created() {
        // await this.getGameAppList(this.initAppList)
        this.loadData()
    }

    loadData() {
        return Promise.all([
            this.getList()
        ])
    }

    async onTabClick(event: any) {
        const loading = this.$loading({
            lock: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)',
            customClass: 'switch-loading'
        })
        await this.loadData()
        loading.close()
    }
}
